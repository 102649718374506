
import {ContentContainerEntity} from '../../entities/content-container.entity';
import {ContentItemEntity} from '../../entities/content-item.entity';

export class SkillsCreator{
    public englishContent: ContentContainerEntity = new ContentContainerEntity();
    public germanContent: ContentContainerEntity = new ContentContainerEntity();
    constructor(){
        this.fillScillsContent();
    }


    private fillScillsContent(): void {
        let skillElement: ContentItemEntity

        //********************************* */
        skillElement=new ContentItemEntity('Betriebssysteme', 'Linux, MS Windows, OSEK OS, VxWorks (Windriver), RTX (CMX), RTEMS (Open Source), QNX (RIM), Free RTOS');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Operating systems', 'Linux, MS Windows, OSEK OS, VxWorks (Windriver), RTX (CMX), RTEMS (Open Source), QNX (RIM), Free RTOS');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('Programmier Sprachen', 'C, C++, C#, Java, Java Script, Typescript, Perl, HTML, CSS, Turbo Pascal, Visual Basic, VHDL, UML');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Programming languages', 'C, C++, C#, Java, Java Script, Typescript, Perl, HTML, CSS, Turbo Pascal, Visual Basic, VHDL, UML');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('Compiler', 'GNU, Microsoft, Rose, Diab, Keil, IAR');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Compiler', 'GNU, Microsoft, Rose, Diab, Keil, IAR');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('Frameworks/Bibliotheken', '.Net, ASP.Net, WPF, MVVC, Angular, Spring Boot, QT, RxJS, Hibernate, JPA, Apache Freemarker');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Frameworks/Libraries', '.Net, ASP.Net, WPF, MVVC, Angular, Spring Boot, QT, RxJS, Hibernate, JPA, Apache Freemarker');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('Test', 'JUnit, Mockito, Karma, Jasmine, proprietäre Systemtests');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Test', 'JUnit, Mockito, Karma, Jasmine, proprietary system tests');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('Datenbanken', 'MS SQL Server, Oracle');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Databases', 'MS SQL Server, Oracle');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('Protokolle', 'TCP/IP, OSEK NM, REST, CAN Open, OPC UA, Modbus');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Protocols', 'TCP/IP, OSEK NM, REST, CAN Open, OPC UA, Modbus');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('IDE / Entwicklungs-Tools', 'Eclipse, Visual Studio, GNU Toolchain, IAR Embedded Workbench, IntelliJ');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('IDE / Development-Tools', 'Eclipse, Visual Studio, GNU Toolchain, IAR Embedded Workbench, IntelliJ');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('Software Tools', 'Atlassian Toolchain, Jira, Bitbucket, Confluence, Git, Azure DevOps Server, Jenkins, TFS, ClearCase, ClearQuest, Subversion, DOORS, Enterprise Architect, Together, Rational Rose, Artisan Realtime Studio, Rhapsody, Lotus Notes, Lauterbach Debugger');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Software Tools', 'Atlassian Toolchain, Jira, Bitbucket, Confluence, Git, Azure DevOps Server, Jenkins, TFS, ClearCase, ClearQuest, Subversion, DOORS, Enterprise Architect, Together, Rational Rose, Artisan Realtime Studio, Rhapsody, Lotus Notes, Lauterbach Debugger');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('Project Management', 'SAP PS, Clarity, Open Workbench, MS Project, ProAlpha');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Project Management', 'SAP PS, Clarity, Open Workbench, MS Project, ProAlpha');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('Verfahren', 'Scrum, Kanban, V-Modell <br>SPICE und CMM Prozessmodelle, Prozesse nach CMMI Reifegradmodell, Level 3, IEC 62304, NSQ100');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Processes', 'Scrum, Kanban, V-Modell <br>SPICE und CMM process model, processes according to CMMI process model, Level 3, IEC 62304, NSQ100');
        this.englishContent.addContentItem(skillElement);

        //********************************* */
        skillElement=new ContentItemEntity('Hardware', 'ARM, µController verschiedener Hersteller <br>Freescale 8xx, 82xx, 83xx, Infineon C16x, XC16x, Intel Pentium <br>Freescale HC05, HC08, MPC 5xx, MPC 8xx <br>Tektronik Oscilloscope, Lauterbach Digital Analyzer, Keysight Digitizer');
        this.germanContent.addContentItem(skillElement);

        skillElement=new ContentItemEntity('Hardware', 'ARM, µController of various manufacturers <br>Freescale 8xx, 82xx, 83xx, Infineon C16x, XC16x, Intel Pentium <br>Freescale HC05, HC08, MPC 5xx, MPC 8xx <br>Tektronik Oscilloscope, Lauterbach Digital Analyzer, Keysight Digitizer');
        this.englishContent.addContentItem(skillElement);

        //*******************************  
           



    }
}