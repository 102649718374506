import { Component, OnInit } from '@angular/core';
import {ImprintCreator} from './imprint-creator';
import {DisclaimerCreator} from './disclaimer-creator';
import {ContentContainerEntity} from '../../entities/content-container.entity';
import {TranslateService} from '@ngx-translate/core';
import { ReplaySubject  } from 'rxjs';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.sass']
})
export class ImprintComponent implements OnInit {
  private imprintCreator : ImprintCreator = new ImprintCreator();
  private disclaimerCreator : DisclaimerCreator = new DisclaimerCreator();
  public imprintContent: ReplaySubject<ContentContainerEntity> = new ReplaySubject<ContentContainerEntity>();
  public disclaimerContent: ReplaySubject<ContentContainerEntity> = new ReplaySubject<ContentContainerEntity>();

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.setContentLanguage(this.translate.currentLang);
    this.translate.onLangChange.subscribe(() => {
      this.setContentLanguage(this.translate.currentLang);
    });
    
  }

  private setContentLanguage(language: string): void{

    if (language.toLowerCase()==='de'){
      this.imprintContent.next(this.imprintCreator.germanContent);
      this.disclaimerContent.next(this.disclaimerCreator.germanContent);
    } else {
      this.imprintContent.next(this.imprintCreator.englishContent);
      this.disclaimerContent.next(this.disclaimerCreator.englishContent);
    }
  }
}
