
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {ContentDetailEntity} from './content-detail.entity';

export class HTMLContentDetailEntity{
    public content: SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
        input: ContentDetailEntity,
    ){
        this.content = this.sanitizer.bypassSecurityTrustHtml(input.content);
    }
}