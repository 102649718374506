
<div class="TE_NavBar" fxLayout="column" fxLayoutAlign="start" [fxLayoutGap]="buttonGap" >

    <div>
        <button mat-button (click)="onNavigate('start')"> {{'app.components.start.title' | translate}} </button>
    </div>
    <div>
        <button mat-button (click)="onNavigate('projects')"> {{'app.components.projects.title' | translate}} </button>
    </div>
    <div>   
        <button mat-button (click)="onNavigate('cv')"> {{'app.components.cv.title' | translate}} </button>
    </div>
    <div>
        <button mat-button (click)="onNavigate('skills')"> {{'app.components.skills.title' | translate}} </button>
    </div>
    <div>
        <button mat-button (click)="onNavigate('contact')"> {{'app.components.contact.title' | translate}} </button>
    </div>
    <div>
        <button mat-button (click)="onNavigate('imprint')"> {{'app.components.imprint.title' | translate}} </button>
    </div>

    <app-language-selector fxHide.gt-xs class="TE_TopBarButton"></app-language-selector>

</div>
