<div fxLayout="column" fxLayoutAlign="start center" class="TE_Page" >

    <div  fxFlex fxLayout="row wrap" fxLayout.xs="column" fxLayout.sm="column"  fxLayoutAlign="start start"  >
        <img fxFlex fxHide.xs class="TE_Image" src="../assets/Matthias_Hecht.jpg" alt="Matthias Hecht">

        <img fxFlex fxHide.gt-xs class="TE_Image_XS" src="../assets/Matthias_Hecht.jpg" alt="Matthias Hecht">

        <div *ngIf="displayGerman" class= "TE_Content" fxFlex fxLayout="column" >

            <div class="TE_Body">
                TruEngineering steht für feines Software Engineering aus Trudering in München. 
                <br>
                <br>
                TruEngineering steht ebenso für meine echte Leidenschaft: 
                Technik und Engineering.
                <br>
                <br>
                Nach vielen Jahren und verschiedenen Rollen in der Produktentwicklung im industriellen Umfeld, unterstütze ich meine Kunden seit 2021 als freiberuflicher Ingenieur.
                <br>
                Was zeichnet mich aus?
                <ul>
                    <li>Starke analytische und kommunikative Fähigkeiten  </li>
                    <li>Der Wille und die Ausdauer Ihr Problem zu lösen</li>
                    <li>Ein breites Portfolio an Technologien aus verschiedenen Branchen</li>
                </ul>
                Bei Ihrem nächsten Projekt unterstütze ich Sie gerne, nehmen Sie unverbindlich <a routerLink="/contact" skipLocationChange>Kontakt</a> mit mir auf.
            </div>
            <div class="TE_Footer">Ihr Matthias Hecht</div>
        </div>

        <div *ngIf="!displayGerman" class= "TE_Content" fxFlex fxLayout="column" >

            <div class="TE_Body">
                TruEngineering stands for delicate software engineering from Trudering in München. 
                <br>
                <br>
                TruEngineering also stands for my true passion: 
                Technics and engineering.
                <br>
                <br>
                After many years and different roles in product development in the industrial environment, I support my customers as a freelance engineer since 2021.
                <br>
                What are my main strength?
                <ul>
                    <li>Strong analytical and communication skills</li>
                    <li>The willingness and endurance to solve your task</li>
                    <li>A broad portfolio of skills of different industry sectors</li>
                </ul>
                I am eager to support you in your next project. Just get in <a routerLink="/contact" skipLocationChange>contact</a> with me without any obligation.
            </div>
            <div class="TE_Footer">Yours Matthias Hecht</div>
        </div>

    </div>
    

</div>
