<div class="TE_TopBar" fxHide.xs fxLayout="row" fxLayoutAlign="space-between center">
    <div class="TE_TopBarSpace">
        <button mat-icon-button class="TE_TopBarButton" [matTooltip]=" 'app.main-page.tooltipMenu'  | translate " aria-label="Menu" (click)="onSidenavToggle()">
            <mat-icon class="TE_TopBarIcon" >menu</mat-icon>
        </button>
    </div>
    <img class="TE_TopBarLogo" src="../assets/Logo.png"   alt="TruEngineering">
    <div class="TE_TopBarSpace">
      <app-language-selector class="TE_TopBarButton"></app-language-selector>
    </div>
</div>

<div class="TE_TopBar_XS" fxHide.gt-xs fxLayout="row" fxLayoutAlign="space-between center">
    <div class="TE_TopBarSpace_CS">
        <button mat-icon-button class="TE_TopBarButton" [matTooltip]=" 'app.main-page.tooltipMenu'  | translate " aria-label="Menu" (click)="onSidenavToggle()">
            <mat-icon class="TE_TopBarIcon" >menu</mat-icon>
        </button>
    </div>

    <img class="TE_TopBarLogo_XS" src="../assets/Logo.png"   alt="TruEngineering">
    <div class="TE_TopBarSpace_XS">
    </div>
</div>
