import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsComponent } from '../app/main/components/projects/projects.component';
import { ImprintComponent } from '../app/main/components/imprint/imprint.component';
import { CvComponent } from '../app/main/components/cv/cv.component';
import { SkillsComponent } from '../app/main/components/skills/skills.component';
import { StartComponent } from '../app/main/components/start/start.component';
import { ContactComponent } from '../app/main/components/contact/contact.component';

const routes: Routes = [
  { path: 'projects', component: ProjectsComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'cv', component: CvComponent },
  { path: 'skills', component: SkillsComponent },
  { path: 'start', component: StartComponent },
  { path: 'contact', component: ContactComponent },
  { path: '', component: StartComponent },
  // { path: '', redirectTo: '/start', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
