import { Component, OnInit  } from '@angular/core';
import {ScreenWidth, StyleService} from '../../services/style.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.sass']
})
export class MainPageComponent implements OnInit {
  opened : boolean = true;
  sidenavMode : string = 'side';
  constructor(
    private style: StyleService,
  ) { 
    style.screenWidth.subscribe(screenSize => {
      this.setSidenavMode(screenSize);
    })
  }

  ngOnInit(): void {
    }



  private setSidenavMode(screenSize: ScreenWidth){
    if (screenSize == ScreenWidth.small){
      if (this.sidenavMode === "side"){
        this.opened = false;
      }
      this.sidenavMode="over";
    }else{
      if (this.sidenavMode === "over"){
        this.opened = true;
      }
      this.sidenavMode="side";
    }
  }

  public onOpenContent(content: string){
    if (this.sidenavMode === "over"){
      this.opened = false;
    }

  }

  public onMenuButtonClick(){
    this.opened = !this.opened;
  }
}
