
import {ContentContainerEntity} from '../../entities/content-container.entity';
import {ContentItemEntity} from '../../entities/content-item.entity';

export class ImprintCreator{
    public englishContent: ContentContainerEntity = new ContentContainerEntity();
    public germanContent: ContentContainerEntity = new ContentContainerEntity();
    constructor(){
        this.fillImprintContent();
    }


    private fillImprintContent(): void {
        let contact: ContentItemEntity

        //********************************* */
        contact=new ContentItemEntity('Impressum', 'Matthias Hecht');
        contact.addContentDetail('Marianne-Plehn-Str 16');
        contact.addContentDetail('81825 München - Trudering');
        contact.addContentDetail('Telefon +49 89 45 666 396');
        contact.addContentDetail('Mail matthias.hecht@truengineering.de');
        contact.addContentDetail('Umsatzsteuer-Identifikationsnummer DE 3426 0080 5');

        this.germanContent.addContentItem(contact);

        contact=new ContentItemEntity('Imprint', 'Matthias Hecht');
        contact.addContentDetail('Marianne-Plehn-Str 16');
        contact.addContentDetail('81825 München - Trudering');
        contact.addContentDetail('Telefon +49 89 45 666 396');
        contact.addContentDetail('Mail matthias.hecht@truengineering.de');
        contact.addContentDetail('VAT ID number DE 3426 0080 5');
        this.englishContent.addContentItem(contact);

        //*******************************  
           



    }
}