
import {ContentDetailEntity} from './content-detail.entity';

export class ContentItemEntity{
    public listOfContentDetailEntity: ContentDetailEntity[] = [];
    constructor(
        public title1: string,
        public title2: string,
    ){}

    public addContentDetail(contentDetail : string): void{
        let contentDetailEntity: ContentDetailEntity = new ContentDetailEntity(contentDetail);
        this.listOfContentDetailEntity.push(contentDetailEntity);
    }

}