import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPageComponent } from './main/components/main-page/main-page.component';
import { ProjectsComponent } from './main/components/projects/projects.component';
import { ImprintComponent } from './main/components/imprint/imprint.component';
import { CvComponent } from './main/components/cv/cv.component';
import { SkillsComponent } from './main/components/skills/skills.component';
import { StartComponent } from './main/components/start/start.component';
import { ContactComponent } from './main/components/contact/contact.component';
import { NavbarComponent } from './main/components/navbar/navbar.component';
import { TitlebarComponent } from './main/components/titlebar/titlebar.component';
import { LanguageSelectorComponent } from './main/components/language-selector/language-selector.component';
import { ContentComponent } from './main/components/content/content.component';



import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule} from './main/modules/shared.module'
import { MaterialModule} from './main/modules/material.module'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    ProjectsComponent,
    ImprintComponent,
    CvComponent,
    SkillsComponent,
    StartComponent,
    ContactComponent,
    NavbarComponent,
    TitlebarComponent,
    LanguageSelectorComponent,
    ContentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    }),
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
