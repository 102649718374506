import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {ContentContainerEntity} from '../../entities/content-container.entity';
import {HTMLContentContainerEntity} from '../../entities/html-content-container.entity';
import {StyleService} from '../../services/style.service';
import { ReplaySubject  } from 'rxjs';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html'
})
export class ContentComponent implements OnInit {
  
  @Input()
  public content: ReplaySubject<ContentContainerEntity>;
  
  @Input()
  public bulletList: boolean= true;
  
  public contentContainer: HTMLContentContainerEntity = undefined;

  public widthLeft: string="200px";
  public widthRight: string="400px";
  
  constructor(
    private style: StyleService,
    private sanitizer: DomSanitizer,
  ) { 
    this.contentContainer = new HTMLContentContainerEntity(this.sanitizer, new ContentContainerEntity());
  }

  ngOnInit(): void {
    this.style.screenWidthInPixel.subscribe(screenWidth => {
      this.adoptToScreenWidth(screenWidth);
    });
    this.content.subscribe(newContent => {
      this.contentContainer = new HTMLContentContainerEntity(this.sanitizer, newContent);
    });
    
  }

  private adoptToScreenWidth (screenWidth: number){
    if (screenWidth > 960){
      screenWidth = 960;
    }
    let widthLeft = 180;

    let widthRight = screenWidth - widthLeft - 40;
    if (widthRight < 370){
      widthRight = screenWidth - 40;
    }

    this.widthLeft = widthLeft.toString() + "px";
    this.widthRight = widthRight.toString() + "px";
  }


}
