
import {ContentContainerEntity} from '../../entities/content-container.entity';
import {ContentItemEntity} from '../../entities/content-item.entity';

export class ProjectsCreator{
    public englishContent: ContentContainerEntity = new ContentContainerEntity();
    public germanContent: ContentContainerEntity = new ContentContainerEntity();
    constructor(){
        this.fillProjectContent();
    }


    private fillProjectContent(): void {
        let project: ContentItemEntity

        //********************************* */
        project=new ContentItemEntity('07/2021 - bis jetzt', 'Gerätesoftware für ein medizinisches Gerät ');
        project.addContentDetail('Kunde: <a href="http://www.bbraun.com">BBraun Melsungen</a>');
        project.addContentDetail('Die Gerätesoftware für ein Medizintechnikprodukt muss überarbeitet und erneuert werden. Wesentliche Entwicklungsziele sind, die Software für eine neue Hardwareplattform anzupassen und die Wartbarkeit der Software zu verbessern.');
        project.addContentDetail('Meine Rolle: System Engineering, Software Entwicklung');
        project.addContentDetail('Technologien: Linux, C#, WPF, C++, CAN Bus, DOORS, Enterprise architect, IEC 62304');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('07/2021 - till now', 'Software for a medical device ');
        project.addContentDetail('Customer: <a href="http://www.bbraun.com">BBraun Melsungen</a>');
        project.addContentDetail('The software for a medical device must be renewed. Main development goals are, to adapt the software to a new hardware platform and to improve the maintainability of the software.');
        project.addContentDetail('My role: System engineering, software development');
        project.addContentDetail('Technologies: Linux, C#, WPF, C++, CAN Bus, DOORS, Enterprise architect, IEC 62304');
        this.englishContent.addContentItem(project);

        //********************************* */
        project=new ContentItemEntity('01/2020 - 06/2021', 'Basissoftware für Zeiterfassungs- und Zutrittskontrollgeräte');
        project.addContentDetail('Kunde: <a href="http://www.pcs.com">PCS Systemtechnik</a>');
        project.addContentDetail('Die Basissoftware, bestehend aus der Gerätesoftware und einer Kommunikations- und Verwaltungssoftware musste erneuert werden. Wesentliche Entwicklungsziele waren, die Geräte als IoT Geräte mit einer Cloud betreiben zu können und die Bedienbarkeit zu vereinfachen.');
        project.addContentDetail('Meine Rolle: System Engineering, Software Entwicklung');
        project.addContentDetail('Technologien: <br>Geräte Software: ARM, Linux, QT, C++ <br>Cloud Dienst: Java, Spring Boot, Typescript, Angular <br>Kommunikation über REST Ressourcen');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('01/2020 - 06/2021', 'Basic software for time recording and access control devices');
        project.addContentDetail('Customer: <a href="http://www.pcs.com">PCS Systemtechnik</a>');
        project.addContentDetail('The basic software, comprising the embedded software of the devices and a communication and control application, had to be renewed. Main development goals were to run the devices as IoT devices in a cloud service and to improve the usability.');
        project.addContentDetail('My role: System engineering, software development');
        project.addContentDetail('Technologies: <br>Embedded software: ARM, Linux, QT, C++ <br>Cloud service: Java, Spring Boot, Typescript, Angular <br>Communication using REST resources');
        this.englishContent.addContentItem(project);

        //********************************* */
        project=new ContentItemEntity('04/2019 - 12/2020', 'Software für die Verwaltung von Zutrittsberechtigungen');
        project.addContentDetail('Kunde: <a href="http://www.pcs.com">PCS Systemtechnik</a>');
        project.addContentDetail('Der Zutrittskontrollmanager DEXICON der PCS Systemtechnik musste erneuert werden. Wesentliche Entwicklungsziele waren, die Software als mehrmandantenfähigen Cloud Service betreiben zu können und die Bedienbarkeit zu vereinfachen.');
        project.addContentDetail('Meine Rolle: Projektleitung, System Engineering, Software-Entwicklung');
        project.addContentDetail('Technologien: Java, Spring Boot, Typescript, Angular');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('04/2019 - 12/2020', 'Access control software');
        project.addContentDetail('Customer: <a href="http://www.pcs.com">PCS Systemtechnik</a>');
        project.addContentDetail('The access control manager DEXICON of PCS Systemtechnik had to be renewed. Main development goals were to run the software as multi-tenant cloud service and to improve the usability.');
        project.addContentDetail('My role: Project management, system engineering, software development');
        project.addContentDetail('Technologies: Java, Spring Boot, Typescript, Angular');
        this.englishContent.addContentItem(project);
        
        //********************************* */
        project=new ContentItemEntity('07/2019 - 10/2019', 'Analysetool für Projektstundenerfassung');
        project.addContentDetail('Kunde: <a href="http://www.pcs.com">PCS Systemtechnik</a>');
        project.addContentDetail('Um die interne Kapazitätsplanung zu verbessern, wurde ein Tool erstellt, um die, in der ERP Software Pro Alpha geplanten und kontierten Projektstunden auszulesen und zu visualisieren.');
        project.addContentDetail('Meine Rolle: Software Entwicklung, System Engineering');
        project.addContentDetail('Technologien: Java, Spring Boot, Apache Freemarker');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('07/2019 - 10/2019', 'Analysis tool for project time tracking');
        project.addContentDetail('Customer: <a href="http://www.pcs.com">PCS Systemtechnik</a>');
        project.addContentDetail('The analysis tool was created to improve the internal resource planning of the project resources. It reads and visualizes the planned and booked project hours from the ERP software pro Alpha.');
        project.addContentDetail('My role: software development, system engineering');
        project.addContentDetail('Technologies: Java, Spring Boot, Apache Freemarker');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('10/2018 - 04/2019', 'Analysetool für Changerequests und Bugs');
        project.addContentDetail('Kunde: <a href="http://www.cohu.com">Cohu (Multitest)</a>');
        project.addContentDetail('Um den Change Management Prozess zu verbessern, wurde ein Tool entwickelt um die, in Lotus Notes verwalteten Issues auszulesen und zu visualisieren.');
        project.addContentDetail('Meine Rolle: Software Entwicklung, System Engineering');
        project.addContentDetail('Technologien: C#, .net, ASP.net');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('10/2018 - 04/2019', 'Analysis tool for change requests and bugs');
        project.addContentDetail('Customer: <a href="http://www.cohu.com">Cohu (Multitest)</a>');
        project.addContentDetail('The tool was created to improve the change management process. It reads and visualizes the issues, recorded in Lotus Notes.');
        project.addContentDetail('My role: software development, system engineering');
        project.addContentDetail('Technologies: C#, .net, ASP.net');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('01/2015 - 09/2018', 'Leistungsmessgerät zur Betriebsdatenerfassung');
        project.addContentDetail('Kunde: <a href="http://www.ipsensor.de">GT Elektronik</a>');
        project.addContentDetail('Entwicklung eines modularen Leistungsmesssystems zur Betriebsdatenerfassung einer großen Anzahl (120) elektrischer Verbraucher. Das Entwicklungsziel war die Produktentwicklung bis zur Serienreife incl. der zugehörigen Produktionshilfsmittel und der Konfigurationssoftware für die Anwender.');
        project.addContentDetail('Meine Rolle: Projektleitung, System Engineering, Software Entwicklung, Hardware Entwicklung');
        project.addContentDetail('Technologien: <br>Geräte: ARM Cortex M3, M4, Free RTOS, IAR, C/C++ <br>Konfigurationssoftware: C#, .net <br>Produktionswerkzeuge: C#, .net');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('01/2015 - 09/2018', 'Power meter');
        project.addContentDetail('Customer: <a href="http://www.ipsensor.de">GT Elektronik</a>');
        project.addContentDetail('Development of a modular power meter to measure the operating data of a large size of electrical loads (up to 120). The development goal was the development of the product till start of series production including the production tools and the configuration software for the user.');
        project.addContentDetail('My role: Project management, System engineering, Software development, Hardware development');
        project.addContentDetail('Technologies: <br>Devices: ARM Cortex M3, M4, Free RTOS, IAR, C/C++ <br>Configuration software: C#, .net <br>Production tools: C#, .net');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('06/2015 - 03/2016', 'Prüfsystem für Reaktorbaugruppen');
        project.addContentDetail('Kunde: <a href="http://www.framatome.com">Framatome (Areva NP)</a>');
        project.addContentDetail('Entwicklung eines Mess-, Justage- und Prüfsystems zur Kalibrierung von Baugruppen zur Messung der Position der Steuerstäbe in Kernkraftwerken. Wesentliches Entwicklungsziel war die Qualifizierung eines Frequenzgenerators (Sinus) hinsichtlich Frequenzstabilität und Verzerrungsfreiheit.');
        project.addContentDetail('Meine Rolle: Projektleitung, System Engineering, Software Entwicklung');
        project.addContentDetail('Technologien: C#, .net, Keysight L4534A Digitizer');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('06/2015 - 03/2016', 'Production test for control devices of a nuclear power plant');
        project.addContentDetail('Customer: <a href="http://www.framatome.com">Framatome (Areva NP)</a>');
        project.addContentDetail('Development of a system to measure, adjust and test components for measuring the positions of the control rods in a nuclear power plant. Main focus was the qualification of a frequency generator (sinus) regarding frequency stability and absence of distortion.');
        project.addContentDetail('My role: Project management, system engineering, software development');
        project.addContentDetail('Technologies: C#, .net, Keysight L4534A Digitizer');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('01/2013 - 06/2015', 'Entwicklung einer ERP Software');
        project.addContentDetail('Kunde: <a href="http://www.gt-elektronik.com">GT Elektronik</a>');
        project.addContentDetail('Entwicklung einer ERP Software zur Verwaltung der wesentlichen firmeninternen Daten: Angebote / Aufträge / Rechnungen / Lieferscheine / Stücklisten / Versionsstände / Artikel / Lager');
        project.addContentDetail('Meine Rolle: Projektleitung, System Engineering, Software Entwicklung');
        project.addContentDetail('Technologien: C#, .net, SQL server');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('01/2013 - 06/2015', 'Development of an ERP software');
        project.addContentDetail('Customer: <a href="http://www.gt-elektronik.com">GT Elektronik</a>');
        project.addContentDetail('Development of an ERP software for management of offers, orders, invoices, shipping orders, bill of materials, articles, versions, stock');
        project.addContentDetail('My role: Project management, system engineering, software development');
        project.addContentDetail('Technologies: C#, .net, SQL server');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('04/2014 - 10/2014', 'Stromsensor für Gleichströme');
        project.addContentDetail('Kunde: <a href="http://www.gt-elektronik.com">GT Elektronik</a>');
        project.addContentDetail('Entwicklung eines Sensors zur Messung von kleinen Gleichströmen. Wesentliches Entwicklungsziel war die Messung von Strömen im Bereich von 30mA für einen DC Fehlerstrom Schutzschalter.');
        project.addContentDetail('Meine Rolle: System Engineering, Hardware Entwicklung');
        project.addContentDetail('Technologien: Altera CPLDs, VHDL');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('04/2014 - 10/2014', 'DC current sensor');
        project.addContentDetail('Customer: <a href="http://www.gt-elektronik.com">GT Elektronik</a>');
        project.addContentDetail('Development of a sensor for small DC current. Main development goal was the measurement of currents in the range of 30mA for a DC fault current protection.');
        project.addContentDetail('My role: System engineering, hardware development');
        project.addContentDetail('Technologies: Altera CPLDs, VHDL');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('01/2013 - 4/2014', 'SCADA Software für Umspannwerke');
        project.addContentDetail('Kunde: <a href="http://www.ren.pt">REN</a>');
        project.addContentDetail('An der kundenspezifischen Überwachungssoftware für das Monitoring des Hochspannungsnetzes von Portugal wurden verschiedene Erweiterungen durchgeführt.');
        project.addContentDetail('Meine Rolle: Projektleitung, System Engineering');
        project.addContentDetail('Technologien: Intel Prozessoren, Linux, QT, C++');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('01/2013 - 4/2014', 'SCADA software for substations');
        project.addContentDetail('Customer: <a href="http://www.ren.pt">REN</a>');
        project.addContentDetail('Diverse changes to the customer specific monitoring software. This software is part of a surveillance system of the high voltage power grid in Portugal.');
        project.addContentDetail('My role: Project management, system engineering');
        project.addContentDetail('Technologies: Intel CPUs, Linux, QT, C++');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('02/2012 - 12/2012', 'Universelle Sensoranbindung');
        project.addContentDetail('Kunde: <a href="http://www.gi-de.com">Giesecke & Devrient</a>');
        project.addContentDetail('Banknotenbearbeitungsmaschinen für Europa müssen ein, durch die EZB vorgegebenes, Schnittstellenprotokoll für Auswertesensoren unterstützen. In dem Projekt wurde dieses Protokoll für die Maschinen von G&D entwickelt.');
        project.addContentDetail('Meine Rolle: System Engineering, Software Entwicklung');
        project.addContentDetail('Technologien: Freescale 82xx/83xx, VxWorks, GNU Toolchain, C++');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('02/2012 - 12/2012', 'Universal sensor connection protocol');
        project.addContentDetail('Customer: <a href="http://www.gi-de.com">Giesecke & Devrient</a>');
        project.addContentDetail('Banknote processing machines in Europe need to support a specific interface protocol for banknote evaluation sensors. This protocol is specified by the European central bank. The project goal was, to implement this protocol in the machines of G&D.');
        project.addContentDetail('My role: System engineering, Software development');
        project.addContentDetail('Technologies: Freescale 82xx/83xx, VxWorks, GNU Toolchain, C++');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('05/2011 - 01/2012', 'Seriennummernleser für Banknoten');
        project.addContentDetail('Kunde: <a href="http://www.gi-de.com">Giesecke & Devrient</a>');
        project.addContentDetail('Für die russische Zentralbank sollten die Banknotenbearbeitungsmaschinen des Typs BPS200 mit einem Seriennummernleser für Banknoten ausgestattet werden. Die Entwicklung umfasste die Anbindung der CIS Kamerazeile und die Integration der Auswertealgorithmik in die bestehende Maschinenarchitektur.');
        project.addContentDetail('Meine Rolle: Projektleitung, System Engineering, Software Entwicklung');
        project.addContentDetail('Technologien: Freescale 82xx/83xx, RTEMS, GNU Toolchain, C++');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('05/2011 - 01/2012', 'Serial number reader for banknotes');
        project.addContentDetail('Customer: <a href="http://www.gi-de.com">Giesecke & Devrient</a>');
        project.addContentDetail('For the Russian central bank, the machines of type BPS200 should be equipped with a serial number reader for banknotes. The development project comprised the integration of the contact image sensor and the respective evaluation software in the architecture of the machine.');
        project.addContentDetail('My role: Project management, system engineering, software development');
        project.addContentDetail('Technologies: Freescale 82xx/83xx, RTEMS, GNU Toolchain, C++');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('03/2008 - 04/2011', 'Adaptionstool für Banknotensensoren');
        project.addContentDetail('Kunde: <a href="http://www.gi-de.com">Giesecke & Devrient</a>');
        project.addContentDetail('Die Auswertealgorithmik von Banknotenbearbeitungsmaschinen muss auf die jeweilige Landeswährung adaptiert werden. Im Rahmen des Projektes wurde ein modulares Adaptionstool entwickelt, mit dem die entsprechenden Adaptionsparameter, für alle Sensoren der G&D Firmengruppe, erstellt werden können.');
        project.addContentDetail('Meine Rolle: Projektleitung, System Engineering, Software Entwicklung');
        project.addContentDetail('Technologien: C#, .net');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('03/2008 - 04/2011', 'Adaptation tool for banknote sensors');
        project.addContentDetail('Customer: <a href="http://www.gi-de.com">Giesecke & Devrient</a>');
        project.addContentDetail('The evaluation algorithms of banknote processing machines need to be adopted to the respective national currency. The content of the project was, to develop a modular adaptation tool, to create the respective parameters for all sensors of G&D machinery.');
        project.addContentDetail('My role: Project management, system engineering, software development');
        project.addContentDetail('Technologies: C#, .net');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('08/2008 - 06/2009', 'Hardware Replacement');
        project.addContentDetail('Kunde: <a href="http://www.gi-de.com">Giesecke & Devrient</a>');
        project.addContentDetail('Der abgekündigte Prozessor vom Typ Infineon C16x auf einer Baugruppe musste Software kompatibel durch den Nachfolgetyp Infineon XC16x ersetzt werden.');
        project.addContentDetail('Meine Rolle: Projektleitung, System Engineering');
        project.addContentDetail('Technologien: Infineon XC16x, CMX, Keil Compiler, C/C++');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('08/2008 - 06/2009', 'Hardware replacement');
        project.addContentDetail('Customer: <a href="http://www.gi-de.com">Giesecke & Devrient</a>');
        project.addContentDetail('The processor of type Infineon C16x was discontinued and needed to be replaced. Main target was, to develop a software compatible device based on the Infineon XC16x micro controller.');
        project.addContentDetail('My role: Project management, system engineering');
        project.addContentDetail('Technologies: Infineon XC16x, CMX, Keil Compiler, C/C++');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('03/2002 - 03/2008', 'Messdatenauswertung für Banknoten');
        project.addContentDetail('Kunde: <a href="http://www.gi-de.com">Giesecke & Devrient</a>');
        project.addContentDetail('Die Systemarchitektur zur Auswertung der Messergebnisse der Banknotensensoren sollte durch ein komplett neues System ersetzt werden. Wesentliches Entwicklungsziel war eine hohe Skalierbarkeit durch die Verteilung der Rechenleistung innerhalb eines Auswerteclusters mit vielen Prozessoren. Die Umstellung der Hardware von Intel 386EX auf Freescale 82xx/83xx Prozessoren musste dabei für die Auswertesoftware kompatibel sein.');
        project.addContentDetail('Meine Rolle: Projektleitung, Systemengineering, Software Entwicklung');
        project.addContentDetail('Technologien: Freescale 82xx/83xx, VxWorks, GNU Toolchain, C++');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('03/2002 - 03/2008', 'Measurement evaluation system for banknotes');
        project.addContentDetail('Customer: <a href="http://www.gi-de.com">Giesecke & Devrient</a>');
        project.addContentDetail('The complete system architecture for evaluating the measurement data of banknotes had to be replaced by a new system. Main focus was high scalability through distributing the processing power on several CPUs in an evaluation cluster. The replacement of the hardware from Intel 386EX to Freescale 82xx/83xx had to be software compatible for the evaluation algorithms.');
        project.addContentDetail('My role: Project management, system engineering, Software development');
        project.addContentDetail('Technologies: Freescale 82xx/83xx, VxWorks, GNU Toolchain, C++');
        this.englishContent.addContentItem(project);        

        //********************************* */
        project=new ContentItemEntity('08/99 – 01/01', 'Prüfsystem für das OSEK Netzwerkmanagement');
        project.addContentDetail('Kunde: <a href="http://www.audi.de">Audi</a>');
        project.addContentDetail('Vor der Integration der Steuergeräte in das Fahrzeugnetzwerk sollten diese bereits auf ihre Schnittstellenkompatibilität getestet werden. Zur Überprüfung des korrekten Verhaltens bzgl. des OSEK Network Managements wurde ein Prüfsystem entwickelt.');
        project.addContentDetail('Meine Rolle: Projektleitung, System Engineering, Software Entwicklung');
        project.addContentDetail('Technologien: Matlab, Simulink');
        this.germanContent.addContentItem(project);

        project=new ContentItemEntity('08/99 – 01/01', 'Test system for OSEK network management');
        project.addContentDetail('Customer: <a href="http://www.audi.de">Audi</a>');
        project.addContentDetail('The ECUs had to be tested in regard to their interface compatibility before the integration in the network of the car. The test system was developed to verify the correct interface implementation of the OSEK network management on the CAN bus.');
        project.addContentDetail('My role: Project management, system engineering, software development');
        project.addContentDetail('Technologies: Matlab, Simulink');
        this.englishContent.addContentItem(project);        

        //*******************************  
           



    }
}