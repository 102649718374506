
import {ContentContainerEntity} from '../../entities/content-container.entity';
import {ContentItemEntity} from '../../entities/content-item.entity';

export class DisclaimerCreator{
    public englishContent: ContentContainerEntity = new ContentContainerEntity();
    public germanContent: ContentContainerEntity = new ContentContainerEntity();
    constructor(){
        this.fillDisclaimerContent();
    }


    private fillDisclaimerContent(): void {
        let contact: ContentItemEntity

        //********************************* */
        contact=new ContentItemEntity('Inhalt der Webseite', 'Die Inhalte dieser Webseite wurden sorgfältig und nach aktuellem Kenntnisstand des Autors erstellt. Wir sind für diese nur im Rahmen der allgemeinen Gesetze verantwortlich, insbesondere für eigene Inhalte nach § 7 TMG, für fremde Inhalte gem. §§ 8 – 10 TMG. Als Diensteanbieter sind wir für fremde Inhalte frühestens ab Kenntniserlangung einer konkreten Rechtsverletzung verantwortlich. Wir behalten uns vor, die informatorischen Inhalte vollständig oder teilweise zu ändern oder löschen, soweit vertragliche Verpflichtungen unberührt bleiben.');
        this.germanContent.addContentItem(contact);

        contact=new ContentItemEntity('Contents of the website', 'The contents of this website were written with due diligence and by the author’s best knowledge. We can be held liable only by general laws, especially for our own contents acc. § 7 TMG (German law on tele-media) and for external contents acc. §§ 8 – 10 TMG. As a Provider of tele-media we can be held liable for external contents only once we have knowledge of a concrete infringement of law. We reserve the right to change or delete contents of this webpage which are not subject to any contractual obligation.');
        this.englishContent.addContentItem(contact);

        //********************************* */
        contact=new ContentItemEntity('Verweisung auf fremde Seiten', 'Inhalte fremder Webseiten, auf die wir direkt oder indirekt verweisen (durch „Hyperlinks“ oder „Deeplinks“), liegen außerhalb unseres Verantwortungsbereiches und machen wir uns nicht zu Eigen. Zum Zeitpunkt der Linksetzung waren für uns keine illegalen Inhalte auf den verlinkten Webseiten erkennbar. Da wir auf die aktuelle und zukünftige Gestaltung die Inhalte der verlinkten Webseiten keinerlei Einfluss haben, distanzieren wir uns ausdrücklich von allen Inhalten verlinkter Webseiten, die nach der Linksetzung verändert wurden. Für alle Inhalte und insbesondere für Schäden, die aus der Nutzung der in den verlinkten Webseiten aufrufbaren Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verlinkt wurde. Erhalten wir von illegalen, rechtswidrigen oder fehlerhaften Inhalten auf Webseiten Kenntnis, die wir verlinken, werden wir die Verlinkung aufheben.');
        this.germanContent.addContentItem(contact);

        contact=new ContentItemEntity('Links on external Websites', 'Contents of external websites on which we are linking direct or indirect (through „hyperlinks“ or „deeplinks“) are beyond our responsibility and are not adopted as our own content. When the links were published, we didn’t have knowledge of any illegal activities or contents on these websites. Since we do not have any control on the contents of these websites, we distance ourselves from all contents of all linked websites, which were updated after the setting of the links. For all contents and especially damages, resulting of the use of the linked websites, only the provider of these linked websites can be held liable. If we receive knowledge of illegal contents on these linked websites, we will delete the according links.');
        this.englishContent.addContentItem(contact);

        //********************************* */
        contact=new ContentItemEntity('Urheberrecht', 'Alle auf dieser Webseite dargestellten Inhalte, also insbesondere Texte, Bilder, Fotos, grafische Darstellungen, Musiken, Marken, Warenzeichen, unterliegen den Normen des deutschen Urheberrechts. Die Verwendung, Vervielfältigung usw. unterliegt den Rechten der jeweiligen Urheber bzw. Rechteverwalter. Wenn Sie solche Werke verwenden wollen, werden wir auf Anfrage gerne den Kontakt zum jeweiligen Urheber/Rechteverwalter herstellen.');
        this.germanContent.addContentItem(contact);

        contact=new ContentItemEntity('Intellectual property rights', 'All content of this website, especially texts, pictures, images, graphical presentations, music, trademarks, brands and so forth, are subject to copyright laws. The use, reproduction and so on are subject to the individual rights of the respective owner of the copyright or administrator of these rights. If you want to use such content, please let us know and we will establish contact with the respective owner/administrator.');
        this.englishContent.addContentItem(contact);

        //*******************************  
           



    }
}