import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,  
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FlexLayoutModule,
        HttpClientModule,
    ],
    exports: [
        CommonModule,
        RouterModule,  
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FlexLayoutModule,
        HttpClientModule,
      ],
    })
  export class SharedModule { }