import { Component, OnInit } from '@angular/core';
import {ProjectsCreator} from './projects-creator';
import {ContentContainerEntity} from '../../entities/content-container.entity';
import {TranslateService} from '@ngx-translate/core';
import { ReplaySubject  } from 'rxjs';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.sass']
})
export class ProjectsComponent implements OnInit {
  private projectsCreator : ProjectsCreator = new ProjectsCreator();
  public content: ReplaySubject<ContentContainerEntity> = new ReplaySubject<ContentContainerEntity>();

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.setContentLanguage(this.translate.currentLang);
    this.translate.onLangChange.subscribe(() => {
      this.setContentLanguage(this.translate.currentLang);
    });
    
  }

  private setContentLanguage(language: string): void{

    if (language.toLowerCase()==='de'){
      this.content.next(this.projectsCreator.germanContent);
    } else {
      this.content.next(this.projectsCreator.englishContent);
    }
  }

}
