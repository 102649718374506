import { Injectable } from '@angular/core';
import { ReplaySubject  } from 'rxjs';


export enum ScreenWidth{
    small,
    large
};

export enum ScreenHeight{
    small,
    large
};

@Injectable({
    providedIn: 'root'
}

)
export class StyleService {
    public screenWidth: ReplaySubject<ScreenWidth> = new ReplaySubject<ScreenWidth>();
    public screenHeight: ReplaySubject<ScreenHeight> = new ReplaySubject<ScreenHeight>();
    public screenWidthInPixel: ReplaySubject<number> = new ReplaySubject<number>();


    public setScreenSize(screenWidth: number, screenHeight: number){
        if (screenWidth < 600){
            this.screenWidth.next(ScreenWidth.small);
            this.screenWidthInPixel.next(screenWidth);
        } else {
            this.screenWidth.next(ScreenWidth.large);
            this.screenWidthInPixel.next(screenWidth-130); //Subtraction of TE_SidenavWidth to get visible screen
        }

        if (screenHeight < 600){
            this.screenHeight.next(ScreenHeight.small);
        } else {
            this.screenHeight.next(ScreenHeight.large);
        }

    }
}