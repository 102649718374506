import { Component, OnInit } from '@angular/core';
import {CVCreator} from './cv-creator';
import {ContentContainerEntity} from '../../entities/content-container.entity';
import {TranslateService} from '@ngx-translate/core';
import { ReplaySubject  } from 'rxjs';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.sass']
})
export class CvComponent implements OnInit {

  private cvCreator: CVCreator = new CVCreator();
  public content: ReplaySubject<ContentContainerEntity> = new ReplaySubject<ContentContainerEntity>();

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.setContentLanguage(this.translate.currentLang);
    this.translate.onLangChange.subscribe(() => {
      this.setContentLanguage(this.translate.currentLang);
    });
    
  }

  private setContentLanguage(language: string): void{

    if (language.toLowerCase()==='de'){
      this.content.next(this.cvCreator.germanContent);
    } else {
      this.content.next(this.cvCreator.englishContent);
    }
  }

}
