import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.sass']
})
export class StartComponent implements OnInit {

  public displayGerman : boolean = true;
  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.setContentLanguage(this.translate.currentLang);
    this.translate.onLangChange.subscribe(() => {
      this.setContentLanguage(this.translate.currentLang);
    });
    
  }

  private setContentLanguage(language: string): void{

    if (language.toLowerCase()==='de'){
      this.displayGerman = true;
    } else {
      this.displayGerman = false;
    }
  }

}
