import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.sass']
})
export class TitlebarComponent implements OnInit {

  @Output()
  public menuButtonClick: EventEmitter<void> = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

  onSidenavToggle(){
    this.menuButtonClick.next();
  }
}
