
import {ContentContainerEntity} from '../../entities/content-container.entity';
import {ContentItemEntity} from '../../entities/content-item.entity';

export class CVCreator{
    public englishContent: ContentContainerEntity = new ContentContainerEntity();
    public germanContent: ContentContainerEntity = new ContentContainerEntity();
    constructor(){
        this.fillCVContent();
    }


    private fillCVContent(): void {
        let cvElement: ContentItemEntity

        //********************************* */
        cvElement=new ContentItemEntity('Seit 02/2021', 'Experte für Projektleitung, Software Architektur, System- und Softwareentwicklung');
        cvElement.addContentDetail('Selbständig');
        this.germanContent.addContentItem(cvElement);

        cvElement=new ContentItemEntity('02/2021 - till now', 'Expert for system development, project management, software architecture, software development');
        cvElement.addContentDetail('Freelancer');
        this.englishContent.addContentItem(cvElement);

        //********************************* */
        cvElement=new ContentItemEntity('02/2019 - 01/2021', 'Entwicklungsleiter');
        cvElement.addContentDetail('PCS Systemtechnik GmbH');
        this.germanContent.addContentItem(cvElement);

        cvElement=new ContentItemEntity('02/2019 - 01/2021', 'Head of development');
        cvElement.addContentDetail('PCS Systemtechnik GmbH');
        this.englishContent.addContentItem(cvElement);

        //********************************* */
        cvElement=new ContentItemEntity('10/2018 - 03/2019', 'Abteilungsleiter');
        cvElement.addContentDetail('Multitest elektronische Systeme GmbH');
        this.germanContent.addContentItem(cvElement);

        cvElement=new ContentItemEntity('10/2018 - 03/2019', 'Head of department');
        cvElement.addContentDetail('Multitest elektronische Systeme GmbH');
        this.englishContent.addContentItem(cvElement);

        //********************************* */
        cvElement=new ContentItemEntity('02/2012 - 09/2018', 'Geschäftsführer');
        cvElement.addContentDetail('Sycomp Electronic GmbH');
        this.germanContent.addContentItem(cvElement);

        cvElement=new ContentItemEntity('02/2012 - 09/2018', 'Managing director');
        cvElement.addContentDetail('Sycomp Electronic GmbH');
        this.englishContent.addContentItem(cvElement);

        //********************************* */
        cvElement=new ContentItemEntity('03/2007 - 01/2012', 'Abteilungsleiter');
        cvElement.addContentDetail('Giesecke und Devrient GmbH');
        this.germanContent.addContentItem(cvElement);

        cvElement=new ContentItemEntity('03/2007 - 01/2012', 'Head of department');
        cvElement.addContentDetail('Giesecke und Devrient GmbH');
        this.englishContent.addContentItem(cvElement);

        //********************************* */
        cvElement=new ContentItemEntity('02/2002 - 02/2007', 'Projektleiter');
        cvElement.addContentDetail('Giesecke und Devrient GmbH');
        this.germanContent.addContentItem(cvElement);

        cvElement=new ContentItemEntity('02/2002 - 02/2007', 'Project manager');
        cvElement.addContentDetail('Giesecke und Devrient GmbH');
        this.englishContent.addContentItem(cvElement);

        //********************************* */
        cvElement=new ContentItemEntity('02/2001 - 01/2002', 'Entwicklungsingenieur');
        cvElement.addContentDetail('Giesecke und Devrient GmbH');
        this.germanContent.addContentItem(cvElement);

        cvElement=new ContentItemEntity('02/2001 - 01/2002', 'Development engineer');
        cvElement.addContentDetail('Giesecke und Devrient GmbH');
        this.englishContent.addContentItem(cvElement);

        //********************************* */
        cvElement=new ContentItemEntity('08/1999 - 01/2001', 'Fachreferent Software');
        cvElement.addContentDetail('Audi AG');
        this.germanContent.addContentItem(cvElement);

        cvElement=new ContentItemEntity('08/1999 - 01/2001', 'Senior specialist software');
        cvElement.addContentDetail('Audi AG');
        this.englishContent.addContentItem(cvElement);

        //********************************* */
        cvElement=new ContentItemEntity('12/1994 - 07/1999', 'Entwicklungsingenieur');
        cvElement.addContentDetail('Lauterbach Datentechnik GmbH');
        this.germanContent.addContentItem(cvElement);

        cvElement=new ContentItemEntity('12/1994 - 07/1999', 'Development engineer');
        cvElement.addContentDetail('Lauterbach Datentechnik GmbH');
        this.englishContent.addContentItem(cvElement);

        //********************************* */
        cvElement=new ContentItemEntity('04/1994 - 11/1994', 'Entwicklungsingenieur');
        cvElement.addContentDetail('Deutsche Versuchsgesellschaft für Luft- und Raumfahrt');
        this.germanContent.addContentItem(cvElement);

        cvElement=new ContentItemEntity('04/1994 - 11/1994', 'Development engineer');
        cvElement.addContentDetail('Deutsche Versuchsgesellschaft für Luft- und Raumfahrt');
        this.englishContent.addContentItem(cvElement);

       


        //*******************************  
           



    }
}