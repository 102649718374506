import { Component, OnInit, HostListener } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StyleService} from '../app/main/services/style.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'TruEngineering';
  public screenWidth: any;
  public screenHeight: any;

  constructor(
    translate: TranslateService,
    private style: StyleService
  ){
    translate.addLangs(['en', 'de'])
    translate.setDefaultLang('de');
    translate.use('de');
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.style.setScreenSize(this.screenWidth, this.screenHeight);
    }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.style.setScreenSize(this.screenWidth, this.screenHeight);
  }
}
