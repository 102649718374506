import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguageSelectorComponent} from '../language-selector/language-selector.component';
import { Router } from '@angular/router';
import {ScreenHeight, StyleService} from '../../services/style.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass']
})
export class NavbarComponent implements OnInit {

  @Output()
  openContent: EventEmitter<string> = new EventEmitter<string>();

  public buttonGap: string = "10px";
  constructor(
    public translate: TranslateService,
    private router : Router,
    private style: StyleService,
  ) { }

  ngOnInit(): void {
    this.style.screenHeight.subscribe(screenHeight => {
      if (screenHeight == ScreenHeight.small){
        this.buttonGap = '0px';
      } else {
        this.buttonGap = '10px';
      }

    })
  }

  onNavigate(component: string){
    this.openContent.next(component);
    this.router.navigateByUrl('/' + component, {skipLocationChange: true});
  }

  onChangeLanguage(){
    LanguageSelectorComponent.toggleLanguage(this.translate);
  }

}
