<div fxLayout="column" fxLayoutAlign="start center" class="TE_Page" >

    <div *ngFor = "let contentItem of contentContainer.listOfContentItemEntity" fxFlex fxLayout="row wrap" fxLayoutAlign="start start"  >
        <div class="TE_LeftBox" [ngStyle]="{width: widthLeft}">
            {{contentItem.title1}}
        </div>
        <div class="TE_RightBox" [ngStyle]="{width: widthRight}" >
            {{contentItem.title2}}
            <ul *ngIf="bulletList">
                <div *ngFor = "let contentDetail of contentItem.listOfContentDetailEntity">
                    <li [innerHtml]="contentDetail.content">
                    </li>
                </div>
            </ul>
            <div *ngIf="!bulletList" style="margin-top: 20px">
                <div *ngFor = "let contentDetail of contentItem.listOfContentDetailEntity">
                    <div [innerHtml]="contentDetail.content">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

