
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {HTMLContentDetailEntity} from './html-content-detail.entity';
import {ContentItemEntity} from './content-item.entity';
import {ContentDetailEntity} from './content-detail.entity';

export class HTMLContentItemEntity{
    public listOfContentDetailEntity: HTMLContentDetailEntity[] = [];
    public title1: string;
    public title2: string;

    constructor(
        private sanitizer: DomSanitizer,
        input: ContentItemEntity,
    ){
        this.title1 = input.title1;
        this.title2 = input.title2;
        input.listOfContentDetailEntity.forEach(contentDetailEntity => {
            this.addContentDetail(contentDetailEntity);
        });
    }

    public addContentDetail(contentDetailEntity : ContentDetailEntity): void{
        let hTMLContentDetailEntity: HTMLContentDetailEntity = new HTMLContentDetailEntity(this.sanitizer, contentDetailEntity);
        this.listOfContentDetailEntity.push(hTMLContentDetailEntity);
    }

}