import { DomSanitizer } from '@angular/platform-browser';
import {ContentContainerEntity} from './content-container.entity';
import {ContentItemEntity} from './content-item.entity';
import {HTMLContentItemEntity} from './html-content-item.entity';


export class HTMLContentContainerEntity{
    public listOfContentItemEntity: HTMLContentItemEntity[] = [];
    
    constructor(
        private sanitizer: DomSanitizer,
        input: ContentContainerEntity,
    ){
        input.listOfContentItemEntity.forEach(contentItemEntity => {
            this.addContentItem(contentItemEntity);
        });
    }
    public addContentItem(contentItemEntity: ContentItemEntity): void{
        this.listOfContentItemEntity.push(new HTMLContentItemEntity(this.sanitizer, contentItemEntity));
    }

}