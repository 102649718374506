<app-titlebar (menuButtonClick)="onMenuButtonClick()"></app-titlebar>

<mat-sidenav-container class="TE_SidenavContainer">
    <mat-sidenav class="TE_Sidenav" #sidenav [mode]=sidenavMode [opened]=opened >
        <app-navbar (openContent)="onOpenContent($event)"></app-navbar>
    </mat-sidenav>

    <mat-sidenav-content >
      <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
  


