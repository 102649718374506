
import {ContentContainerEntity} from '../../entities/content-container.entity';
import {ContentItemEntity} from '../../entities/content-item.entity';

export class ContactCreator{
    public englishContent: ContentContainerEntity = new ContentContainerEntity();
    public germanContent: ContentContainerEntity = new ContentContainerEntity();
    constructor(){
        this.fillContactContent();
    }


    private fillContactContent(): void {
        let contact: ContentItemEntity

        //********************************* */
        contact=new ContentItemEntity('Kontakt', 'Matthias Hecht');
        contact.addContentDetail('Marianne-Plehn-Str 16');
        contact.addContentDetail('81825 München - Trudering');
        contact.addContentDetail('+49 89 45 666 396');
        contact.addContentDetail('<a href="mailto:matthias.hecht@truengineering.de">matthias.hecht@truengineering.de</a>');
        this.germanContent.addContentItem(contact);

        contact=new ContentItemEntity('Contact', 'Matthias Hecht');
        contact.addContentDetail('Marianne-Plehn-Str 16');
        contact.addContentDetail('81825 München - Trudering');
        contact.addContentDetail('+49 89 45 666 396');
        contact.addContentDetail('<a href="mailto:matthias.hecht@truengineering.de">matthias.hecht@truengineering.de</a>');
        this.englishContent.addContentItem(contact);

        //*******************************  
           



    }
}