import { Component, OnInit, Inject } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
  }

  onLanguageSelect(){
    LanguageSelectorComponent.toggleLanguage(this.translate);

  }

  public static toggleLanguage(translate: TranslateService):void{
    let currentLang = translate.currentLang;
    let langs = translate.langs;
    let index = langs.indexOf(currentLang, 0);
    if (index < langs.length-1){
      index = index + 1;
    }else{
      index =0;
    }
    translate.use(langs[index]);

  }
}
